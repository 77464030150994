import "core-js/stable";
import "regenerator-runtime/runtime";
import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

window.isSP = function () {
  return $(".u-dp-s:visible").length;
};
const LazyLoad = require("lazyload"),
  SmoothScroll = require("smooth-scroll");

// Picturefill
document.createElement("picture");

// LazyLoad
new LazyLoad({
  elements_selector: ".lazyload",
});
// Smoothscroll
SmoothScroll('a[href*="#"]', {
  updateURL: false,
  header: "[data-scroll-header]",
});

// Pagetop
$(function () {
  const topBtn = $(".c-pagetop");
  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function () {
    $("body,html").animate(
      {
        scrollTop: 0,
      },
      500
    );
    return false;
  });
});

$(function () {
  // SP header menu
  $(".c-headerMenu").click(function () {
    $(this).toggleClass("_active_");
    $("body").toggleClass("_gnav-show_");
  });
  $(".c-gnavClose .__elem").click(function (event) {
    event.preventDefault();
    $("body").removeClass("_gnav-show_");
    $(".c-headerMenu").removeClass("_active_");
  });
  // Accordion
  $(".c-gnavList__link._down_").click(function () {
    $(this).next().slideToggle();
    $(this).toggleClass("_drop_");
  });
  $(".m-accordion__ttl").click(function () {
    $(this).next().slideToggle();
    $(this).toggleClass("_active_");
  });
  $(".c-gnavBtn__item._date_ .c-gnavBtn__link").click(function (event) {
    $("body").removeClass("_gnav-show_");
    $(".c-headerMenu").removeClass("_active_");
  });
});

const headerHeight = $(".c-header").outerHeight(),
  urlHash = location.hash;
if (urlHash && !window.isSP()) {
  $("body,html").stop().scrollTop(0);
  setTimeout(function () {
    const target = $(urlHash),
      position = target.offset().top - headerHeight;
    $("body,html").stop().animate({ scrollTop: position }, 500);
  }, 100);
}
