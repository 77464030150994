try {
  window.$ = window.jQuery = require("jquery");
  window._ = require("lodash");

  // Require('bootstrap');
} catch (e) {
  /* */
}

import picturefill from "picturefill";
picturefill();
